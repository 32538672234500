import React from 'react';
import { InputLabel, NativeSelect } from '@material-ui/core';

import useCurrentUser from 'hooks/useCurrentUser';
import ChevronDownDarkIcon from 'public/icons/chevron-down-dark.svg';
import styles from 'styles/components/gigSetup/Container.module.scss';

const GGFileType = ({ values, setFieldValue }) => {
  const user = useCurrentUser();

  const ggTypeOptions = [
    { id: 'ftl', value: 'ftl', name: 'Free Trial Link' },
    { id: 'campaign', value: 'campaign', name: 'Campaign' },
  ];

  const ggFreeTrialDayOptions = [
    { id: 1, value: 1, name: '1 day' },
    { id: 2, value: 3, name: '3 days' },
    { id: 3, value: 7, name: '1 week' },
    { id: 4, value: 14, name: '2 weeks' },
    { id: 5, value: 30, name: '1 month' },
    { id: 6, value: 90, name: '3 months' },
    { id: 7, value: 180, name: '6 months' },
    { id: 8, value: 360, name: '1 year' },
  ];

  if (!user?.c4_creator_id) return null;

  return (
    <>
      <div className={styles.infoBox}>
        <span>Connected OnlyFans account:</span>{' '}
        <strong style={{ wordBreak: 'break-word' }}>{user.c4_creator_username}</strong>
      </div>

      <>
        <InputLabel>Campaign Type</InputLabel>

        <NativeSelect
          fullWidth
          name="gg_type"
          className={styles.select}
          value={values.gg_type}
          onChange={e => setFieldValue('gg_type', e.target.value)}
          IconComponent={ChevronDownDarkIcon}
        >
          {ggTypeOptions.map(option => (
            <option key={option.id} value={option.value}>
              {option.name}
            </option>
          ))}
        </NativeSelect>
      </>

      {values.gg_type === 'ftl' && (
        <>
          <InputLabel>Free Trial Days</InputLabel>
          <NativeSelect
            fullWidth
            name="gg_free_trial_days"
            className={styles.select}
            value={values.gg_free_trial_days}
            onChange={e => setFieldValue('gg_free_trial_days', e.target.value)}
            IconComponent={ChevronDownDarkIcon}
          >
            {ggFreeTrialDayOptions.map(option => (
              <option key={option.id} value={option.value}>
                {option.name}
              </option>
            ))}
          </NativeSelect>
        </>
      )}
    </>
  );
};

export default GGFileType;
