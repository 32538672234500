import { createSlice } from '@reduxjs/toolkit';

export const gigsSlice = createSlice({
  name: 'gigs',
  initialState: {
    orders: [],
    mapping: {},
    invitation: {},
    gigMessages: {},
    counts: {
      orders: 0,
      open: 0,
      invites: 0,
      pending: 0,
      active: 0,
      completed: 0,
      cancelled: 0,
      archived: 0,
      applications: 0,
    },
  },

  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload;
    },

    setGig: (state, action) => {
      state.mapping[action.payload.id] = action.payload;
    },

    updateGigPrice: (state, action) => {
      const gig = state.mapping[action.payload.id];
      const amount = action.payload.amount;
      const milestonesCount = gig.milestones?.length || 1;
      const amountPerMilestone = amount / milestonesCount;

      state.mapping[action.payload.id] = {
        ...gig,
        amount,
        milestones: gig.milestones.map(milestone => ({ ...milestone, amount: amountPerMilestone })),
      };
    },

    setInvitation: (state, action) => {
      state.invitation[action.payload.id] = action.payload;
    },

    setCounts: (state, action) => {
      state.counts = action.payload;
    },

    incrementCount: (state, action) => {
      state.counts = { ...state.counts, [action.payload]: state.counts[action.payload] + 1 };
    },

    setGigMessage: (state, action) => {
      state.gigMessages = {
        ...state.gigMessages,
        [action.payload.conversation_id]: action.payload,
      };
    },
  },
});

export const {
  setOrders,
  setGig,
  updateGigPrice,
  setInvitation,
  setCounts,
  incrementCount,
  setGigMessage,
} = gigsSlice.actions;

export default gigsSlice.reducer;
